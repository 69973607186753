import lilya from "../../images/Lilya.png";
import mime from "../../images/Mask group.png";
import stars from "../../images/aboutstars.svg";
import heart from "../../images/aboutheart.svg";

function About() {
    return (
        <div className="background-violet about position-relative" id='about'>
            <img className='about__stars' src={stars}/>
            <img className='about__heart' src={heart}/>
            <h2 className="about__heading">О нас</h2>
            <div className="flex-row about__container">
                <img className='about__picture' src={lilya} alt='Lilya'/>
                <div className="about__text-container">
                    <h3 className="about__small-heading">Привет!</h3>
                    <p className="about__text">Я Лилия Энгельман - актриса в прошлом, режиссер в настоящем, создатель и
                        руководитель театра
                        ШумиМ. <span style={{fontWeight: '600'}}>И я создаю волшебство.</span></p>
                    <p className="about__text" style={{maxWidth: '650px'}}>Однажды, ещё будучи актрисой, я поняла, что
                        не хочу делать так, как
                        говорит режиссер. Хочу
                        выражать свои мысли своим языком. Задумалась, <span style={{fontWeight: '600'}}>а какой он мой язык?</span> Так
                        мне пришло
                        осознание, что
                        это <span style={{fontWeight: '600'}}>искусство без слов</span>. Мысли мои в то время занимали
                        слишком серьезно относящиеся
                        к жизни
                        взрослые, и эти мысли зародили идею театра: <span style={{fontWeight: '600'}}>«Создать такую атмосферу, в которой безопасно
                        дурачиться даже самому серьезному взрослому!»</span></p>
                    <p className="about__text"><span style={{fontWeight: '600'}}>Получилось!</span> Наша команда состоит
                        исключительно из
                        профессиональных
                        актеров, клоунов и артистов оригинального
                        жанра. <span style={{fontWeight: '600'}}>Нам удается тонко чувствовать</span> границы между
                        нами и зрителями, поэтому <span style={{fontWeight: '600'}}>от одного взгляда на нас, взрослые расплываются в улыбке.</span>
                    </p>
                    <p className="about__text">Секрет завораживающих костюмов в деталях. Наши портные не отдадут в
                        эксплуатацию костюм, пока в нем не будет играть каждая рюша и каждая пуговка.</p>
                    <p className="about__text">Нас запоминают надолго, потому что <span style={{fontWeight: '600'}}>мы не просто красивая картинка, мы -
                        светлые эмоции</span>, которых порой так не хватает в жизни.</p>
                    <p className="about__subtext">Это ли не волшебство?</p>
                </div>
            </div>
            <img className="about__background-picture" src={mime} alt='Mime'/>
        </div>
    );
}

export default About;