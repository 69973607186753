import heart from '../../images/footer-heart.svg';
import inst from '../../images/openmoji_instagram.svg';
import tel from '../../images/telegramm.svg';
import vk from '../../images/vk.svg';
import file from "../../files/repertoire.pdf";

function Footer() {
    return (
        <div className="footer">
            <div className='flex-row flex-align-center footer__main-container'>
                <div className='flex-column flex-space_between footer__text-container'>
                    <p className='footer__main-text'>Контакты:</p>
                    <p className='footer__main-text'>+7 981 100 6060</p>
                    <div className='flex-row flex-space_between flex-align-center'>
                        <a className='footer__buttons' href={'https://www.instagram.com/shumimteatr/?igsh=bHJ3eDNjdWhlYWdq'} target='_blank'><img src={inst} alt="inst"/></a>
                        <a className='footer__buttons' href={'https://t.me/Shumimteatr'} target='_blank'><img src={tel} alt="tel"/></a>
                        <a className='footer__buttons' href={'https://vk.com/shumimteatr'} target='_blank'><img src={vk} alt="vk"/></a>
                    </div>
                </div>
                <img className='footer__heart' src={heart} alt="heart"/>
                <div className='flex-column flex-space_between footer__text-container'>
                    <a className='footer__buttons' href={'#about'}><p className='footer__main-text'>О нас</p></a>
                    <a className='footer__buttons' href={file} target='_blank'><p className='footer__main-text'>Скачать весь репертуар</p></a>
                    <a className='footer__buttons' href={'#form'}><p className='footer__main-text'>Оставить заявку</p></a>
                </div>
            </div>
            <div className='footer__small'>
                <div className='flex-row footer__small-container'>
                    <p className='footer__main-text'>© Шумим. Все права защищены</p>
                    <div className='flex-column flex-align-center footer__name'>
                        <p className='footer__main-text'>Энгельман Лилия Рафаиловна</p>
                        <p className='footer__main-text'>НПД №19270177 от 06.09.2022 г.</p>
                    </div>
                    <a className='footer__buttons' href={'#'}><p className='footer__main-text'>Политика
                        конфиденциальности</p></a>
                </div>
            </div>
        </div>
    );
}

export default Footer;