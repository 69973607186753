import React from "react";
import Slider from "react-slick";
import review1 from "../../images/review1.png";
import dot from "../../images/dot.svg";
import review2 from "../../images/revphoto2.png";
import review3 from "../../images/revphoto3.png";
import review4 from "../../images/revphoto4.png";
import review5 from "../../images/revphoto5.png";
import {CustomArrow} from "../CustomArrow/CustomArrow";

export const CustomSlider = ({customArrowNextClassList, customArrowPrevClassList}) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <CustomArrow customArrowClassList={customArrowNextClassList}/>,
        prevArrow: <CustomArrow customArrowClassList={customArrowPrevClassList}/>,
        responsive: [
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            <div className='slide-container'>
                <div className='flex-column flex-align-center flex-space_between reviews__review'>
                    <div className='reviews__review-container flex-column flex-align-center'>
                        <img className='review__photo' src={review1} alt='reviewer'/>
                        <h3 className='review__name'>Алёна Филиппова</h3>
                        <p className='review__job'>Режиссер и организатор событий</p>
                        <p className='review__comment'>Дорогой театр Шумим! Большое спасибо вашему творческому
                            коллективу за
                            плодотворное неоднократное
                            сотрудничество. Вы очень-очень классные!!!!!! Такие яркие, артистичные, веселые,
                            отзывчивые
                            и
                            креативные! И что не мало важно, ответственные. Приглашайте ребят на праздник 🎉 Ваш
                            праздник
                            с
                            ними
                            превратится в сказку🎄💥👏👏👏</p>
                    </div>
                    <img className='review__dot' src={dot} alt=''/>
                </div>
            </div>
            <div className='slide-container'>
                <div className='flex-column flex-align-center flex-space_between reviews__review'>
                    <div className='reviews__review-container flex-column flex-align-center'>
                        <img className='review__photo' src={review2} alt='reviewer'/>
                        <h3 className='review__name'>Светлана Корытько</h3>
                        <p className='review__job'>Руководитель первого детского эстрадного центра</p>
                        <p className='review__comment'>Спасибо большое за помощь в открытии эстрадной школы!! И
                            взрослые
                            и
                            дети были в восторге от Ваших актёров! Мимы безумно обаятельные! Костюмы необычные и
                            интересные!
                            Очень рады были сотрудничать именно с Вами )</p>
                    </div>
                    <img className='review__dot' src={dot} alt=''/>
                </div>
            </div>
            <div className='slide-container'>
                <div className='flex-column flex-align-center flex-space_between reviews__review'>
                    <div className='reviews__review-container flex-column flex-align-center'>
                        <img className='review__photo' src={review3} alt='reviewer'/>
                        <h3 className='review__name'>Кирилл Сорокин</h3>
                        <p className='review__job'>Программный директор “Дорожного радио”</p>
                        <p className='review__comment'>Добрый день! Огромное спасибо вам за субботу!!! Было все
                            чудесно!
                            У
                            вас супер команда профессионалов!</p>
                    </div>
                    <img className='review__dot' src={dot} alt=''/>
                </div>
            </div>
            <div className='slide-container'>
                <div className='flex-column flex-align-center flex-space_between reviews__review'>
                    <div className='reviews__review-container flex-column flex-align-center'>
                        <img className='review__photo' src={review4} alt='reviewer'/>
                        <h3 className='review__name'>Екатерина Романова</h3>
                        <p className='review__job'>Ассистент кастинг-диретора</p>
                        <p className='review__comment'>Волшебники!! Безумная благодарность за ваших артистов и
                            уважение
                            к их
                            таланту с профессионализмом! Благодаря им мы попали просто в другой мир! Дикий восторг
                            испытали!
                            Спасибо огромное! Все серьёзные взрослые превратились в беззаботных счастливых людей на
                            празднике и всё это благодаря вам!</p>
                    </div>
                    <img className='review__dot' src={dot} alt=''/>
                </div>
            </div>
            <div className='slide-container'>
                <div className='flex-column flex-align-center flex-space_between reviews__review'>
                    <div className='reviews__review-container flex-column flex-align-center'>
                        <img className='review__photo' src={review5} alt='reviewer'/>
                        <h3 className='review__name'>Жанна Белова</h3>
                        <p className='review__job'>Организатор выпускных в СПб, Студия Чудес</p>
                        <p className='review__comment'>Лиля, ребята! Мы рады, что с Вами сотрудничали! ВЫ КРУТЫЕ! Вы
                            прекрасно сделали все наши велкомы, наши выпускные с вами удались. будем сотрудничать
                            дальше.
                            СПАСИБО! От “Студии Чудес”</p>
                    </div>
                    <img className='review__dot' src={dot} alt=''/>
                </div>
            </div>
        </Slider>
    );
}
