import ivanova from "../../images/Ivanove.png";
import kushtan from "../../images/Kushtan.png";
import koval from "../../images/Koval.png";
import heart1 from "../../images/teamheart1.svg";
import heart2 from "../../images/teamheart2.svg";

function Team() {
    return (
        <div className="background-violet team position-relative">
            <img src={heart1} className='about__heart-one'/>
            <img src={heart2} className='about__heart-two'/>
            <h2 className='team__heading'>Наша команда</h2>
            <div className="team__subheading-container"><p className='team__subheading'>Наш театр - это огромный штат
                профессиональных артистов.</p>
                <p className='team__subheading'>А это главные феи, без которых магия была бы невозможна:</p></div>
            <div className='flex-row flex-space_between team__container'>
                <div className='flex-column flex-align-center team__member'>
                    <img src={ivanova} alt='Ivanova'/>
                    <h3 className='team__name'>Юлия Иванова</h3>
                    <p className='team__job'>Режиссёр театра</p>
                    <div className='team__quote-bubble flex-column flex-align-center'><p
                        className='team__quote'>«Счастье можно найти даже в темные времена, если
                        не забывать
                        обращаться к свету»</p>
                        <p className='team__quote' style={{margin: '9px 0 0', padding: '0', fontStyle: 'italic'}}>Альбус
                            Дамблдор</p></div>
                </div>
                <div className='flex-column flex-align-center team__member'>
                    <img src={kushtan} alt='Kushtan'/>
                    <h3 className='team__name'>Марина Куштан</h3>
                    <p className='team__job'>Художник-технолог по костюму</p>
                    <div className=' team__quote-bubble flex-column flex-align-center'><p className='team__quote'>Мне
                        интересно проходить этот путь — от идеи
                        образа до его реализации. Это
                        почти всегда
                        непредсказуемо. Люблю создавать красоту и воплощаю идеи театра ШуМим.</p></div>
                </div>
                <div className='flex-column flex-align-center team__member'>
                    <img src={koval} alt='Koval'/>
                    <h3 className='team__name'>Елена Коваль</h3>
                    <p className='team__job'>Администратор, незаменимый помощник в делах театра</p>
                    <div className='team__quote-bubble flex-column flex-align-center' style={{margin: '27px 0 0'}}><p
                        className='team__quote'>Шумим для меня это
                        непредсказуемый мир волшебства, красоты и эмоций, с
                        которым должны
                        познакомиться и
                        несомненно влюбиться все!</p></div>
                </div>
            </div>
        </div>
    );
}

export default Team;