import violetMime from '../../images/Violet mime.svg';
import welcomeZone from '../../images/Welcome Zone.png';
import prince from '../../images/prince.png';
import friends from '../../images/friends.png';
import lamour from '../../images/lamour.png';
import whiteParty from '../../images/whiteParty.png';
import helloSky from '../../images/hellosky.png';
import seaInside from '../../images/seainside.png';
import replines1 from '../../images/replines1.svg';
import replines2 from '../../images/replines 2.svg';
import leftdrop from '../../images/welcomedropleft.svg';
import rightdrop from '../../images/welcomedropright.svg';
import welcomstars from '../../images/starswelcome.svg';
import leftdropparty from '../../images/partydropleft.svg';
import rightdropparty from '../../images/partydropright.svg';
import partystars from '../../images/starsparty.svg';
import replines3 from '../../images/bottombutlinestop.svg';
import replines4 from '../../images/bottombuttlinestop.svg';
import file from "../../files/repertoire.pdf";


function Repertoire() {
    return (
        <div className='repertoire' id='repertoire'>
            <img className='repertoire__violetMime' src={violetMime} alt={'mime'}/>
            <div className='flex-column flex-align-center'>
                <div className='position-relative'>
                    <h1 className='repertoire__main-header'>Репертуар</h1>
                    <img className='repertoire__repline-one' src={replines1}/>
                    <img className='repertoire__repline-two' src={replines2}/>
                </div>
                <span className='repertoire__subtext'>*на сайте представлена только часть наших услуг </span>
                <span
                    className='repertoire__subtext'>для ознакомления с полным перечнем можно скачать репертуар ниже</span>
            </div>
            <div className='flex-row repertoire__event position-relative' id='welcome'>
                <img className='repertoire__drop-left' src={leftdrop}/>
                <img className='repertoire__drop-right' src={rightdrop}/>
                <img className='repertoire__welcome-stars' src={welcomstars}/>
                <div className='flex-column'>
                    <div className='flex-column repertoire__event-texts'>
                        <h2 className='repertoire__event-header'>Welcome Zone</h2>
                        <h3 className='repertoire__event-subheader'>Зона приветствия гостей.</h3>
                        <p className='repertoire__event-text'>Это первые впечатления на вашем мероприятии!</p>
                        <p className='repertoire__event-text'> Принято приглашать гостей за час до начала праздника и
                            как же важно, чтобы время ожидания
                            основного действия прошло в легкой, непринужденной атмосфере, чтобы гостям было комфортно,
                            даже
                            если они не знакомы друг с другом.</p>
                        <p className='repertoire__event-text'> Наполнить это время улыбками и смехом будет весьма
                            кстати.</p>
                        <p className='repertoire__event-text repertoire__event-text-bottom'>Улыбнуть, познакомить или
                            деликатно намекнуть, что вот там
                            находится фото зона или другая
                            активность - в этом мы профи!</p>
                    </div>
                    <p className='repertoire__event-price'>Стоимость от 7 000 ₽</p>
                </div>
                <img className='repertoire__event-picture' src={welcomeZone} alt={'mime'}/>
            </div>
            <div className='repertoire__acts-bg'>
                <div className='flex-column position-relative repertoire__acts' id='acts'>
                    <h2 className='repertoire__acts-header'>Номера</h2>
                    <div className='flex-column repertoire__act'>
                        <img src={prince} alt={'mime'}/>
                        <h3 className='repertoire__act-header'>Интерактивно-развлекательный номер с привлечением гостей
                            из
                            зала</h3>
                        <p className='repertoire__act-text'>Подойдёт для любого события</p>
                        <p className='repertoire__act-text'>Номер исполняют 3 артиста в образе французских мимов</p>
                        <p className='repertoire__act-text'>Продолжительность 7-10 минут, 18 000 ₽</p>
                    </div>
                    <div className='flex-column repertoire__act'>
                        <img src={friends} alt={'mime'}/>
                        <h3 className='repertoire__act-header'>Номер про дружбу двух ангелочков</h3>
                        <p className='repertoire__act-text'>Подойдёт для любого события</p>
                        <p className='repertoire__act-text'>Номер исполняют 2 артиста в образе милых ангелочков</p>
                        <p className='repertoire__act-text'>Продолжительность 5 минут, 15 000 ₽</p>
                    </div>
                    <div className='flex-column repertoire__act'>
                        <img src={lamour} alt={'mime'}/>
                        <h3 className='repertoire__act-header'>Романтический номер</h3>
                        <p className='repertoire__act-text'>Подойдёт для свадьбы</p>
                        <p className='repertoire__act-text'>Номер исполняют 2 артиста в образе белых мимов </p>
                        <p className='repertoire__act-text'>Продолжительность 5 минут, 15 000 ₽</p>
                    </div>
                </div>
            </div>
            <div className='flex-row repertoire__event position-relative'>
                <img className='repertoire__drop-left-party' src={leftdropparty}/>
                <img className='repertoire__drop-right-party' src={rightdropparty}/>
                <img className='repertoire__party-stars' src={partystars}/>
                <div className='flex-column'>
                    <div className='flex-column repertoire__event-texts'>
                        <h2 className='repertoire__event-header'>White Party</h2>
                        <h3 className='repertoire__event-subheader'>Это знаменитая на весь мир «белая» вечеринка! </h3>
                        <p className='repertoire__event-text'>Мы, как никто, знаем обо всех положительных аспектах
                            влияния
                            белого цвета на человека. В нашем
                            арсенале белые шоу, белые фото зоны и костюмы, белые угощения и бумажное шоу..</p>
                        <p className='repertoire__event-text'>Хотите попробовать что-нибудь новенькое и необычное? Мы
                            берем
                            всю организацию на себя!</p>
                    </div>
                </div>
                <img className='repertoire__event-second-picture' src={whiteParty} alt={'mime'}/>
            </div>
            <div className='repertoire__acts-bg-boy'>
                <div className='position-relative repertoire__plays' id={'plays'}>
                    <div>
                        <h2 className='repertoire__acts-header'>Спектакль</h2>
                        <div className='flex-column repertoire__act'>
                            <img src={helloSky} alt={'mime'}/>
                            <h3 className='repertoire__act-header'>Интерактивный спектакль для детей до 7 лет</h3>
                            <p className='repertoire__act-text'>Подойдёт для любого детского праздника</p>
                            <p className='repertoire__act-text'>Номер исполняет 1 артист в образе белого ангелочка</p>
                            <p className='repertoire__act-text'>Продолжительность 30-40 минут, стоимость 29 000 ₽</p>
                        </div>
                    </div>
                    <div>
                        <h2 className='repertoire__acts-header'>Шоу</h2>
                        <div className='flex-column repertoire__act'>
                            <img src={seaInside} alt={'mime'}/>
                            <h3 className='repertoire__act-header'>Интерактивный спектакль для детей до 7 лет</h3>
                            <p className='repertoire__act-text'>Подойдёт для любого события</p>
                            <p className='repertoire__act-text'>Номер исполняет 1 артист в образе белого мима</p>
                            <p className='repertoire__act-text'>Продолжительность 20 минут, стоимость 25 000 ₽</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='position-relative repertoire__button-container'>
                <a href={file} target='_blank' className='repertoire__link'>
                    <button className='repertoire__button'>Скачать весь репертуар</button>
                </a>
                <img src={replines3} className='bottbutline-one'/>
                <img src={replines4} className='bottbutline-two'/>
            </div>
        </div>
    );
}

export default Repertoire;
