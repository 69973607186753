import mime_pic from '../../images/mime.svg';
import button_lines from '../../images/big button lines.svg'
import menu from '../../images/menu.svg';
import Burger from "../Burger/Burger";

function Header(props) {
    return (
        <header className="header">
            <button className="navigation__menu-button" onClick={props.handleMenu}><img src={menu} alt="Меню"/></button>
            <Burger isOpenMenu={props.isOpenMenu}
                    closeMenu={props.closeMenu}/>
            <nav className="flex-row header__nav">
                <a href={'#about'}>
                    <button className="header__nav-button">О нас</button>
                </a>
                <a href={'#repertoire'}>
                    <button className="header__nav-button">Репертуар</button>
                </a>
                <a href={'#form'}>
                    <button className="header__nav-button">Контакты</button>
                </a>
                <a href={'#reviews'}>
                    <button className="header__nav-button">Отзывы</button>
                </a>
            </nav>
            <div className="flex-row flex-space_between header-main-container">
                <div className="flex-column flex-align-center header__heading-container">
                    <h1 className="header__main-heading">ШУМИМ</h1>
                    <h2 className="header__second-heading">ТЕАТР БЕЗМОЛВНОГО ИСКУССТВА</h2>
                    <div className='position-relative'>
                        <a href={'#form'} >
                            <button className="header__big-button">Хочу волшебства!</button>
                        </a>
                        <img className='button-lines' src={button_lines} alt={'mime'}/>
                    </div>
                </div>
                <img className="header__picture" src={mime_pic} alt='mime'/>
            </div>
        </header>
    );
}

export default Header;