import React from 'react';
import './App.css';
import Header from "../Header/Header";
import Navigation from "../Navigation/Navigation";
import About from "../About/About";
import Team from "../Team/Team";
import Repertoire from "../Repertoire/Repertoire";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import Reviews from "../Reviews/Reviews";

function App() {
    const [isOpenMenu, setIsOpenMenu] = React.useState(false);

    function handleMenu() {
        setIsOpenMenu(true)
    }

    function closeMenu() {
        setIsOpenMenu(false)
    }
    return (
        <div className="container">
        <div className="page">
            <Header handleMenu={handleMenu}
                    isOpenMenu={isOpenMenu}
                    closeMenu={closeMenu}/>
            <Navigation/>
            <About/>
            <Team/>
            <Repertoire/>
            <Form/>
            <Reviews/>
            <Footer/>
        </div>
        </div>
    );
}

export default App;
