import formMime from '../../images/form-mime.png';
import formLines from '../../images/form-lines.svg';
import stars from '../../images/formstars.svg';
import * as emailjs from "@emailjs/browser";
import React from "react";

function Form() {
    emailjs.init({
        publicKey: "zvXE2yf9le7UaC6qy",
    });

    const [isFormSent, setIsFormSent] = React.useState(false);
    const [buttonText, setButtonText] = React.useState('Отправить');
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

    const ButtonClassName = `form__button ${isFormSent ? 'form__button-violet' : ''}`;

    function onSubmit(e) {
        e.preventDefault();

        emailjs.sendForm('service_mkm64q5', 'template_n3hp7zx', e.target)
            .then(() => {
                console.log('SUCCESS!');
                setIsButtonDisabled(true)
                e.target.reset();
                setIsFormSent(true);
                setButtonText('Отправлено');
                setTimeout(()=>{
                    setIsButtonDisabled(false)
                    setIsFormSent(false);
                    setButtonText('Отправить');
                }, 5000)
            }, (error) => {
                console.log('FAILED...', error);
                setIsButtonDisabled(true)
                setButtonText('Ошибка');
                setTimeout(()=>{
                    setIsButtonDisabled(false)
                    setIsFormSent(false);
                    setButtonText('Отправить');
                }, 5000)
            });
    }

    return (
        <div className='flex-row form position-relative' id='form'>
            <img src={stars} alt='stars' className='form__stars'/>
            <div className='flex-column flex-align-center form__main'>
                <h2 className='form__header'>Оставьте свой номер телефона</h2>
                <p className='form__subtext'>Перезвоним и обсудим все детали предстоящего мероприятия</p>
                <form id={'form'} className='flex-column flex-align-center form__form-container' onSubmit={onSubmit}>
                    <input className='form__input' type="text" placeholder='Ваше имя' required={true} name="from_name"/>
                    <input className='form__input' type="tel" placeholder='Ваш номер телефона' required={true}
                           name="message"/>
                    <button className={ButtonClassName} type={"submit"} disabled={isButtonDisabled}>{buttonText}</button>
                    <img className='form__image' src={formLines} alt={'lines'}/>
                </form>
            </div>
            <img className='form__mime' src={formMime} alt={'mime'}/>
        </div>
    );
}

export default Form;